import Sortable from 'sortablejs';

export default function formBuilder(activeLocale, locales, attributable_type) {
  return {
    activeLocale: activeLocale,
    locales: locales,
    attributable_type: attributable_type,
    activeFormField: '',
    sortable: null,
    dynAttrs: {},
    openConfirmationModal: false,
    openAdvanced: false,
    messageId: '',
    importCode: '',

    init() {
      // load and use relevant form field data
      document.querySelectorAll('.form-builder__settings-card').forEach((formField) => {
        const da = JSON.parse(formField.dataset.da);
        this.dynAttrs[da.id] = { "count": da.count };

        if (da.required) {
          this.toggleRequired(da.id, true);
        }

        if (da.private) {
          this.togglePrivate(da.id, true);
        }
      });

      // initialize sortable
      this.sortable = new Sortable(formFieldList, {
        dataIdAttr: 'data-da-id',
        handle: '.form-card__header',
        filter: '.form-builder__header',
        group: 'list-group-item',
        onUpdate: () => this.updateSortable(),
      });

      // highlight first form field and style sortable arrows
      const firstFormField = document.querySelector('.form-builder__field-wrapper');
      if (firstFormField) {
        this.activateFormField(firstFormField.dataset.daId)
        this.hideFirstAndLastArrows();
      };

      // hide the spinner once everything is loaded
      this.$nextTick(() => {
        document.querySelectorAll('.loading-ring__wrapper').forEach((spinner) => {
          spinner.classList.add('hidden');
        });
      });
    },

    changeMarkerURL() {
      const marker = document.querySelector('gmp-advanced-marker');
      const image = marker.querySelector('img');
      image.src = this.$refs.markerURL.value || "https://bpart-default-assets.s3.eu-central-1.amazonaws.com/img/map-default-marker.png";
    },

    hasFormFields() {
      return Object.keys(this.dynAttrs).length > 0;
    },

    hasNoFormFields() {
      return !this.hasFormFields();
    },

    // ADDING FORM FIELDS

    showNewFieldSelector() {
      this.deactivateFormFields();
      this.activateSettingsField('formFieldSelector');
    },

    // Note(wh): New form fields are cloned from templates included in the DOM. These do not have an id.
    // For Alpine to identify these new form fields, we generate a unique temporary id when a new field is added.
    // When adding the form field to the DOM, Alpine will insert this temporary id where needed.
    // This id is also used to replace the child_indexes of the fields_for (named INDEX_TO_REPLACE), so Rails
    // will correctly identify the data for each new form field (e.g. topic[dynamic_attributes_attributes][child_index][sort_order]).

    addField(type, object) {
      let id = 1;
      if (Object.keys(this.dynAttrs).length > 0) {
        // To make sure that the new id (that will be used as a child index in the fields_for) does not overlap
        // with the existing child indexes, we multiply the highest existing id by 4
        id = Math.max(...Object.keys(this.dynAttrs)) * 4;
      }
      // We identify new form fields by a negative count, e.g. in this.isExistingFormField(id)
      this.dynAttrs[id] = { "count": -1 };

      this.deactivateFormFields();

      this.addNewFormField(id, type, object);
      this.addNewSettingsField(id, type, object);

      const newFieldBottomButton = document.querySelector('#newFormFieldButtonBottom');
      this.$nextTick(() => newFieldBottomButton?.scrollIntoView({ block: 'end', behavior: 'smooth' }));
    },

    addNewFormField(id, type, object) {
      const formFieldTemplate = document.getElementById(`new_${type}_field`);
      const newFormField = formFieldTemplate.content.firstElementChild.cloneNode(true);
      const formFieldList = document.getElementById('formFieldList');
      formFieldList.appendChild(newFormField);

      this.initialiseFormField(newFormField, id, type, object);

      this.activateFormField(newFormField.dataset.daId);

      this.updateSortable();
    },

    addNewSettingsField(id, type, object) {
      const formSettingsTemplate = document.getElementById(`new_settings_${type}_field`);
      const newSettingsField = formSettingsTemplate.content.firstElementChild.cloneNode(true);
      const formFieldSettings = document.querySelector('.form-builder__sidebar-content');
      formFieldSettings.appendChild(newSettingsField);

      this.initialiseSettingsField(newSettingsField, id, type, object);
    },

    initialiseFormField(formField, id, type, object) {
      formField.dataset.daId = id;

      const formFieldHeader = formField.querySelector('.form-card');
      formFieldHeader.setAttribute('x-bind:class', `activeFormField === '${id}' ? 'form-card--active' : ''`);

      const formFieldHeaderDelete = formFieldHeader.querySelector('.form-card__delete-button');
      formFieldHeaderDelete.setAttribute('x-on:click', `deleteFormField('${id}')`);

      const sortOrderInput = formField.querySelector('input[x-ref="sortOrder_"]')
      sortOrderInput.setAttribute('x-ref', `sortOrder_${id}`);
      sortOrderInput.setAttribute('value', id);

      formField.querySelectorAll('input, textarea').forEach((input) => {
        const regex = /INDEX_TO_REPLACE/;
        input.name = input.name.replace(regex, id);
        input.id = input.id.replace(regex, id);
      });

      if (['checkboxes', 'radio', 'select'].includes(type)) {
        this.initialiseOptions(formField, id);
      }

      if (object) this.useImportedFields(formField, id, object);
    },

    useImportedFields(formField, id, object) {
      locales.forEach((locale) => {
        const formFieldForLocale = formField.querySelector(`.form-field[x-show="activeLocale === '${locale}'"]`);
        const name = formFieldForLocale.querySelector('input.form-card__name');
        name.value = object.name[locale];
        if (['text', 'string'].includes(object.attributeType)) {
          const placeholder = formFieldForLocale.querySelector('textarea.form-card__placeholder');
          placeholder.innerHTML = object.placeholder[locale];
        } else if (['item', 'attachment', 'map'].includes(object.attributeType)) {
          const placeholder = formFieldForLocale.querySelector(`#${this.attributable_type}_dynamic_attributes_attributes_${id}_placeholder_${locale}`);
          placeholder.value = object.placeholder[locale];
        } else if (['checkboxes', 'radio', 'select'].includes(object.attributeType)) {
          // remove the default options
          [0, 1, 2].forEach((index) => {
            const optionForLocale = formFieldForLocale.querySelector(`.option__${index}`)
            optionForLocale.remove();
          });

          // add the imported options value to the hidden input field
          const optionsInputForLocale = formFieldForLocale.querySelector(`#optionsInput_${locale}_${id}`);
          optionsInputForLocale.value = object.options[locale].join("\n");

          // add a visual representation for each imported option
          object.options[locale].forEach((option, index) => {
            const optionTemplate = formFieldForLocale.querySelector(`#newOption_${id}_${locale}`);
            const newOption = optionTemplate.content.firstElementChild.cloneNode(true);
            newOption.classList.add(`option_${id}_${index}`);
            newOption.querySelector('.form-card__option').value = option;
            newOption.querySelector('.form-card__option').addEventListener('change', () => this.changeOptions(id));
            newOption.querySelector('.form-card__delete-option').addEventListener('click', () => this.removeOption(id, index));
            formFieldForLocale.appendChild(newOption);
          });
        };
      });
    },

    initialiseOptions(formField, id) {
      formField.querySelector('.button--outline').addEventListener('click', () => this.addOption(id));
      // adapting the x-show attribute to the new id causes Alpine to throw an error in console, so we replace it by an x-bind:class toggling hidden;
      formField.querySelector(`.form-card__add-option`).removeAttribute('x-show');
      formField.querySelector(`.form-card__add-option`).setAttribute('x-bind:class', `activeFormField === '${id}' ? '' : 'hidden'`);

      this.locales.forEach((locale) => {
        const optionsInputForLocale = formField.querySelector(`#optionsInput_${locale}_`);
        optionsInputForLocale.setAttribute('id', `optionsInput_${locale}_${id}`);

        const newOptionForLocale = optionsInputForLocale.parentNode.querySelector(`#newOption__${locale}`);
        newOptionForLocale.setAttribute('id', `newOption_${id}_${locale}`);

        // A new checkboxes, radio or select field always starts with 3 options
        [0, 1, 2].forEach((index) => {
          const optionForLocale = optionsInputForLocale.parentNode.querySelector(`.option__${index}`)
          if (optionForLocale) {
            optionForLocale.classList.add(`option_${id}_${index}`);
            optionForLocale.querySelector('.form-card__option').addEventListener('change', () => this.changeOptions(id));
            optionForLocale.querySelector('.form-card__delete-option').addEventListener('click', () => this.removeOption(id, index));
          }
        });
      });
    },

    initialiseSettingsField(settingsField, id, type, object) {
      settingsField.dataset.daId = id;

      settingsField.querySelectorAll('input').forEach((input) => {
        const regex = /INDEX_TO_REPLACE/;
        input.name = input.name.replace(regex, id);
        input.id = input.id.replace(regex, id);
      });
      // adapting the x-show attribute to the new id causes Alpine to throw an error in console, so we replace it by an x-bind:class toggling hidden;
      settingsField.removeAttribute('x-show');
      settingsField.setAttribute('x-bind:class', `activeFormField === '${id}' ? '' : 'hidden'`);

      const requiredField = settingsField.querySelector(`.${this.attributable_type}_dynamic_required`).querySelector('input[type="checkbox"]')
      requiredField.addEventListener('click', () => this.toggleRequired(id, requiredField.checked));
      const privateField = settingsField.querySelector(`.${this.attributable_type}_dynamic_private`).querySelector('input[type="checkbox"]')
      privateField.addEventListener('click', () => this.togglePrivate(id, privateField.checked));

      if (['text', 'string'].includes(type)) {
        const maxCharsField = settingsField.querySelector(`.${this.attributable_type}_dynamic_maxlength`).querySelector('input[type="number"]')
        maxCharsField.addEventListener('change', () => this.changeMaxChars(id, maxCharsField.value));
      }

      if (object) this.useImportedSettings(settingsField, id, object);
    },

    useImportedSettings(settingsField, id, object) {
      const internal_name = settingsField.querySelector(`#${this.attributable_type}_dynamic_attributes_attributes_${id}_internal_name`);
      internal_name.value = object.internalName;
      const requiredCheckbox = settingsField.querySelector(`#${this.attributable_type}_dynamic_attributes_attributes_${id}_required`);
      requiredCheckbox.checked = object.required;
      const privateCheckbox = settingsField.querySelector(`#${this.attributable_type}_dynamic_attributes_attributes_${id}_private`);
      privateCheckbox.checked = object.private;

      if (['text', 'string'].includes(object.attributeType)) {
        const maxChars = settingsField.querySelector(`#${this.attributable_type}_dynamic_attributes_attributes_${id}_maxlength`);
        maxChars.value = object.maxlength;
        this.changeMaxChars(id, object.maxlength);
      } else if (object.attributeType === 'map') {
        const customMarkerUrl = settingsField.querySelector(`#${this.attributable_type}_dynamic_attributes_attributes_${id}_custom_marker_url`);
        customMarkerUrl.value = object.customMarkerUrl;
      }
    },

    // SORTING FORM FIELDS

    updateSortable() {
      const sortableArray = this.sortable.toArray();
      sortableArray.forEach((id, index) => {
        if (this.$refs[`sortOrder_${id}`]) { this.$refs[`sortOrder_${id}`].value = index + 1 };
      });

      this.hideFirstAndLastArrows();
    },

    moveFormField(direction) {
      const currentFormField = this.$el.closest('.form-builder__field-wrapper'); // the form field we clicked on
      const otherFormField = this.findOtherFormField(currentFormField, direction); // the form field to swap with

      if (otherFormField === null) return;

      switch (direction) {
        case "up":
          currentFormField.parentNode.insertBefore(currentFormField, otherFormField);
          break;
        case "down":
          currentFormField.parentNode.insertBefore(otherFormField, currentFormField);
          break;
      }

      this.updateSortable();
    },

    hideFirstAndLastArrows() {
      document.querySelectorAll('.form-builder__arrow--inactive')?.forEach((arrow) => {
        arrow.classList.remove('form-builder__arrow--inactive');
      });

      const cards = document.querySelectorAll('.form-builder__field-wrapper')

      if (cards.length > 0) {
        const firstCard = cards[0];
        const firstArrow = firstCard.querySelector('.form-builder__arrow.up');
        firstArrow.classList.add('form-builder__arrow--inactive');

        const lastCard = cards[cards.length - 1];
        const lastArrow = lastCard.querySelector('.form-builder__arrow.down');
        lastArrow.classList.add('form-builder__arrow--inactive');
      }
    },

    // Recursive function because there are hidden input fields on the same level as currentFormField that we need to skip
    findOtherFormField(currentFormField, direction) {
      const otherFormField = (direction === "down") ? currentFormField.nextElementSibling : currentFormField.previousElementSibling;

      if (otherFormField === null || otherFormField.classList.contains('form-builder__field-wrapper')) {
        return otherFormField;
      } else {
        return this.findOtherFormField(otherFormField, direction);
      }
    },

    // DELETING FORM FIELDS

    confirmFormFieldDeletion(id) {
      if (id && this.dynAttrs[id].count > 0) {
        this.messageId = id;
        this.openConfirmationModal = true;
      } else {
        this.deleteFormField(id);
      }
    },

    deleteFormField(id, viaConfirmation = false) {
      // If confirmed via the modal, we hide the confirmation message and close the modal
      if (viaConfirmation) {
        this.messageId = '';
        this.openConfirmationModal = false;
      }

      // for an existing form field: hide and flag for destruction,
      // for a new form field: remove from the DOM
      if (this.isExistingFormField(id)) {
        this.$refs[`destroy_${id}`].value = true;

        // Hide the form field
        const formField = document.querySelector(`.form-builder__field-wrapper[data-da-id="${id}"]`);
        formField.classList.add('hidden');

        // Hide the settings card
        const formFieldSettings = document.querySelector(`.form-builder__settings-card[x-show="activeFormField === '${id}'"]`);
        formFieldSettings.classList.add('hidden');
      } else {
        id ||= this.$el.closest('.form-builder__field-wrapper').dataset.daId;
        document.querySelector(`.form-builder__field-wrapper[data-da-id="${id}"]`).remove();
        document.querySelector(`.form-builder__settings-card[data-da-id="${id}"]`).remove();
      }

      delete this.dynAttrs[id];
      this.hideFirstAndLastArrows();
    },

    isExistingFormField(id) {
      return this.dynAttrs[id].count >= 0;
    },

    // SETTING CORRECT STYLING FOR (ACTIVE) FORM FIELDS

    activateFormField(id) {
      this.activeFormField = id.toString();
    },

    deactivateFormFields() {
      this.activeFormField = '';
    },

    activateSettingsField(id) {
      this.activeFormField = id.toString();
    },

    toggleRequired(id, boolean) {
      const formField = document.querySelector(`.form-builder__field-wrapper[data-da-id="${id}"]`);
      if (boolean) {
        formField.querySelectorAll('.form-card__required').forEach((formField) => { formField.classList.remove('hidden'); });
      } else {
        formField.querySelectorAll('.form-card__required').forEach((formField) => { formField.classList.add('hidden'); });
      }
    },

    togglePrivate(id, boolean) {
      const formField = document.querySelector(`.form-builder__field-wrapper[data-da-id="${id}"]`);
      if (boolean) {
        formField.querySelectorAll('.form-card__hint').forEach((formField) => { formField.classList.remove('hidden'); });
      } else {
        formField.querySelectorAll('.form-card__hint').forEach((formField) => { formField.classList.add('hidden'); });
      }
    },

    changeMaxChars(id, value) {
      const formField = document.querySelector(`.form-builder__field-wrapper[data-da-id="${id}"]`);
      if (value === '' || value === '0') {
        formField.querySelector('.form-card__max-chars').textContent = '';
      } else {
        formField.querySelector('.form-card__max-chars').textContent = `0/${value}`;
      };
    },

    // INTERACT WITH FORM FIELD OPTIONS (for checkboxes, radio buttons and select)

    addOption(id) {
      this.locales.forEach((locale) => {
        const formField = document.querySelector(`#optionsInput_${locale}_${id}`)
        const newIndex = formField.parentNode.querySelectorAll('.form-card__option').length;
        const optionTemplate = document.querySelector(`#newOption_${id}_${locale}`);
        const newOption = optionTemplate.content.firstElementChild.cloneNode(true);

        newOption.classList.add(`option_${id}_${newIndex}`);
        newOption.querySelector('.form-card__option').addEventListener('change', () => this.changeOptions(id));
        newOption.querySelector('.form-card__delete-option').addEventListener('click', () => this.removeOption(id, newIndex));

        formField.parentNode.insertBefore(newOption, optionTemplate);
        this.changeOptions(id);
      });
    },

    removeOption(id, index) {
      Array.from(document.querySelectorAll(`.option_${id}_${index}`)).forEach((field) => {
        field.remove();
      });
      this.changeOptions(id);
    },

    changeOptions(id) {
      this.locales.forEach((locale) => {
        const formFieldOptionsInput = document.querySelector(`#optionsInput_${locale}_${id}`)
        const formFieldOptions = formFieldOptionsInput.parentNode.querySelectorAll('.form-card__option');
        let options = [];
        formFieldOptions.forEach((field) => {
          options.push(field.value);
        });
        formFieldOptionsInput.value = options.join("\n");
      });
    },

    // COPY/PASTE FORM CODE

    async copyFormCode() {
      const url = window.location.href.replace('edit_form', 'dynamic_attributes.json');
      const request = new Request(url);
      const response = await fetch(request);
      const data = await response.json();
      const da = JSON.stringify(data.data.dynamicAttributes, null, "\t");
      window.navigator.clipboard.writeText(da);
      this.showMessage('copy_success');
      this.openAdvanced = false;
    },

    importFormCode() {
      try {
        // raise error if current form has linked entry values
        Object.values(this.dynAttrs).every((v) => {
          if (v.count > 0) {
            throw 'cant_override';
          }
        });

        // parse import string into JSON (and raise error if not correct syntax)
        const code = JSON.parse(this.importCode);

        // remove the existing form fields
        Object.keys(this.dynAttrs).forEach((key) => {
          this.deleteFormField(key);
        });

        // build the form fields
        code.forEach((field) => {
          this.addField(field.attributeType, field);
        });

        this.showMessage('import_successfull');
      } catch (e) {
        if (e instanceof SyntaxError) {
          this.showMessage('your_input_seems_incorrect');
        } else if (e === 'cant_override') {
          this.showMessage('cant_override');
        }
      } finally {
        this.openAdvanced = false;
      }
    },

    showMessage(type) {
      const messageTemplate = document.getElementById(type);
      const newMessage = messageTemplate.content.firstElementChild.cloneNode(true);
      const alerts = document.getElementById('turbo_stream_alerts');
      alerts.appendChild(newMessage);
      if (type === 'import_successfull') {
        setTimeout(() => newMessage.remove(), 2000);
      }
    },

    cardsWrapper: {
      ["@click"](event) {
        // We only want to trigger a change when clicking on a form field
        const formField = event.target.closest('.form-builder__field-wrapper');
        if (formField) {
          this.deactivateFormFields();
          this.activateFormField(formField.dataset.daId);
          formField.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      },
    },

    formField: {
      ['x-transition:enter']() {
        return 'transition ease-out duration-100';
      },
      ['x-transition:enter-start']() {
        return 'transform opacity-0';
      },
      ['x-transition:enter-end']() {
        return 'transform opacity-100';
      },
      ['x-transition:leave']() {
        return 'transition ease-in duration-75';
      },
      ['x-transition:leave-start']() {
        return 'transform opacity-100';
      },
      ['x-transition:leave-end']() {
        return 'transform opacity-0';
      },
    },
  }
}
